import React, { useContext } from 'react'
import QuoteEmail from '../components/QuoteEmail'
import Layout from '../components/Global/Layout'
import Loader from '../components/Global/Loader'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import { useTrademarkConfigurator } from '../hooks/useTrademarkConfigurator'

const QuoteEmailPage = (props) => {
  const state = useContext(GlobalStateContext)
  var configurator = useTrademarkConfigurator({ locale: state.locale })

  if (!state.locale) {
    return (
      <Layout url='/quote-email' locale={state.locale}>
        <Loader loading />
      </Layout>
    )
  }

  return (
    <Layout locale={state.locale} url='/quote-email' title='Quote Email'>
      <QuoteEmail locale={state.locale} configurator={configurator} />
    </Layout>
  )
}

export default QuoteEmailPage
