import React, { useState } from 'react'
import axios from '../../axios'
import { findIndex, sumBy } from 'lodash'
import { useLocation } from '@reach/router'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import Loader from '../Global/Loader'
import * as EmailValidator from 'email-validator'
import fbTrack from '../../context/facebook'

const QuoteEmailView = ({ configurator, locale }) => {
  const { state } = useLocation()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [inValidEmail, setInValidEmail] = useState(false)
  const [showResponse, setShowResponse] = useState(false)
  const [showError, setShowError] = useState(false)

  const isCorrectEmail = (value) => {
    return EmailValidator.validate(value)
  }

  const quoteHandler = (e) => {
    e.preventDefault()
    // only in case of button submittion
    // we verify the email
    if (e.target.tagName == 'BUTTON') {
      var invalid_email = !isCorrectEmail(email)
      setInValidEmail(invalid_email)
      // return if action in progress
      if (loading || invalid_email) return
    }
    // set action in progress
    setLoading(true)
    // in case of second attempt after an error is shown
    setShowError(false)
    // req data obj
    var data = {
      locale,
      subtotal: configurator.state.subtotal,
      total: configurator.state.total_price,
      email: email ? email : null,
      service_level: configurator.state.package_type,
      jurisdiction: configurator.state.country_selection,
      items: getItems(configurator),
      tax: {
        descriptor: configurator.state.billing_address.zip
          ? `Tax - postal code ${configurator.state.billing_address.zip}`
          : null,
        amount: configurator.state.taxes ? configurator.state.taxes : 0
      },
      url: state?.url
    }
    axios.defaults.responseType = 'blob'

    axios
      .post(`/quotes`, { ...data })
      .then((res) => {
        if (!data.email) {
          let filename =
            res.headers['content-disposition'].split('filename=')[1]
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
        if (data.email) {
          setShowResponse(true)
        }

        fbTrack('track', 'Lead')
      })
      .catch((err) => {
        setShowError(true)
      })
      .finally(() => setLoading(false))
  }

  return (
    <Container className='quote-page px-0' fluid>
      {/* Loading spinner  */}
      {loading && <Loader loading />}

      <Container className='header-section pt-5 pb-4 px-0' fluid>
        <Container className='mt-3 mt-md-5 py-5 text-white text-center'>
          <Row className='justify-content-center mt-md-5'>
            <Col md='10'>
              <h1>Thank You!</h1>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* email card section */}
      <Container className='px-md-0 mb-md-5 py-5'>
        <Row className='text-center'>
          <Col md='12'>
            <p className='h2 mt-md-4'>
              Thanks for giving us a chance to prove Heirlume is the best choice
              to help you protect your investment in your business.
            </p>
          </Col>
        </Row>
        <Row className='justify-content-center my-5 pt-md-4'>
          <Col md='8'>
            <div className='card rounded-0'>
              <div className='card-body text-center py-3 py-md-5'>
                <Row className='justify-content-center'>
                  <Col md='12'>
                    {showResponse ? (
                      <p>Thank You!</p>
                    ) : (
                      <p>
                        Enter your email below to receive your personalized,
                        no-obligation quote.
                      </p>
                    )}
                  </Col>
                </Row>
                {showResponse ? (
                  <Row className='justify-content-center align-items-center py-3 py-md-4'>
                    <Col className='pr-md-0 h-100 mb-3 mb-md-0' md='6'>
                      <p>An email has been sent to {email}</p>
                    </Col>
                  </Row>
                ) : (
                  <Row className='justify-content-center align-items-center py-3 py-md-4'>
                    <Col className='pr-md-0 h-100 mb-3 mb-md-0' md='6'>
                      <Form.Group controlId='formEmail' className='mb-0'>
                        <Form.Control
                          className='py-3 h-100'
                          type='email'
                          placeholder='Email Address'
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value)
                          }}
                          isInvalid={inValidEmail}
                        />
                      </Form.Group>
                    </Col>
                    <Col md='4'>
                      <Button
                        variant='warning'
                        className='w-100 py-md-3'
                        onClick={quoteHandler}
                      >
                        Send
                      </Button>
                    </Col>
                  </Row>
                )}
                {!showResponse && (
                  <Row className='justify-content-center'>
                    <Col md='6'>
                      <p className='link-text'>
                        Or{' '}
                        <a href='#' onClick={quoteHandler}>
                          {' '}
                          download your quote directly.
                        </a>
                      </p>
                    </Col>
                  </Row>
                )}

                {showError && (
                  <Row>
                    <Col>
                      <p className='error-text mt-3'>
                        <small>An error occurred, please try again!</small>
                      </p>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default QuoteEmailView

function getItems(configurator) {
  var tempObj = []
  configurator.state.cart.map((item) => {
    var tmName = `${item.type} trademark x ${sumTotal(
      configurator.state.cart,
      item.type
    )}`
    if (findIndex(tempObj, { name: tmName }) === -1) {
      tempObj.push({
        name: tmName,
        amount: parseFloat(configurator.state.prices[item.type].toFixed(2))
      })
    }
  })
  return tempObj
}

function sumTotal(cart, type) {
  try {
    return sumBy(
      cart.filter((item) => {
        return item.type == type
      }),
      'qty'
    )
    // eslint-disable-next-line no-empty
  } catch (error) {}
}
